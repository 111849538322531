<template>
  <section class="text-fg-g-primary pb-20 lg:pb-0">
    <div
      :style="!isCheckoutDesktopFlow ? {} : { 'min-height': `calc(100vh - ${height}px)` }"
      :class="[isCheckoutDesktopFlow ? 'flex justify-between items-start' : '']"
    >
      <GuestHeader
        v-if="(header || isOrderDetailsPage) && !isCheckoutDesktopFlow"
        :isLoggedIn="isLoggedIn"
      />
      <router-view :class="isCheckoutDesktopFlow ? 'py-16 px-12 w-full shrink-[20]' : ''" />
      <CheckoutDesktopDetailSidebar v-if="isCheckoutDesktopFlow && !isOnboardingFlow" />
    </div>
    <FooterComponent v-if="checkoutStore.performance === null" />
  </section>
</template>

<script lang="ts">
import { useUserStore } from './stores/user'
import { breakpointsTailwind, useBreakpoints, useResizeObserver } from '@vueuse/core'
import { mapState, mapStores } from 'pinia'
import { defineComponent } from 'vue'
import 'vue-advanced-cropper/dist/style.css'

import FooterComponent from '@/modules/guestUi/components/molecules/FooterComponent.vue'
import GuestHeader from '@/modules/guestUi/components/molecules/GuestHeader.vue'
import CheckoutDesktopDetailSidebar from '@/modules/guestUi/components/organisms/CheckoutDesktopDetailSidebar.vue'
import { usePerformancePaginate } from '@/modules/guestUi/stores/PerformancePaginateStore'
import { useCheckoutStore } from '@/stores/checkout'
import { useSettingStore } from '@/stores/setting'

export default defineComponent({
  name: 'App',
  components: {
    CheckoutDesktopDetailSidebar,
    FooterComponent,
    GuestHeader,
  },
  data() {
    return {
      height: 200,
    }
  },
  mounted() {
    useResizeObserver(this.footer, this.onResize)
    this.updateTheme()
  },
  computed: {
    ...mapStores(useSettingStore, useCheckoutStore, usePerformancePaginate),
    ...mapState(useUserStore, ['isLoggedIn']),
    header(): boolean {
      return !!this.$route.meta?.header
    },
    isOrderDetailsPage(): boolean {
      return this.$route.name === 'order-details'
    },
    footer() {
      return document.querySelector('footer')
    },
    isLargeScreen(): boolean {
      return useBreakpoints(breakpointsTailwind).lg.value
    },
    isCheckoutFlow(): boolean {
      return (
        this.checkoutStore.performance !== null &&
        this.$route.name !== 'setShow' &&
        this.$route.name !== 'showDetails' &&
        this.$route.name !== 'calendar' &&
        this.$route.name !== 'order-details' &&
        this.$route.name !== 'residency-hub'
      )
    },
    isCheckoutDesktopFlow(): boolean {
      return this.isLargeScreen && this.isCheckoutFlow
    },
    isOnboardingFlow(): boolean {
      return !!this.$route.meta?.onboarding
    },
  },
  async serverPrefetch() {
    await this.performancePaginateStore.initializePagination()
  },
  watch: {
    isCheckoutDesktopFlow: {
      immediate: true,
      handler(value: boolean) {
        this.checkoutStore.setIsDesktopFlow(value)
      },
    },
    $route() {
      this.updateTheme()
    },
  },
  methods: {
    onResize() {
      this.height = this.footer?.clientHeight || 200
    },
    updateTheme() {
      if (this.isOnboardingFlow) {
        document.body.setAttribute('class', 'venue-ui')
      } else {
        document.body.setAttribute('class', 'guest-ui bg-body-primary')
      }
    },
  },
})
</script>
