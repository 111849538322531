/// <reference types="vite/client" />
import '../tailwind/input-guest.css'
import { usePostHog } from './common/composables/usePostHog'
import { RouterPlugin } from './router/RouterPlugin'
import { mkGuestRouter } from './router/guest-router'
import { useSettingStore } from './stores/setting'
import 'external-svg-loader'
import { createPinia, setActivePinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { createApp } from 'vue'

import App from '@/App.vue'
import { configureBrowserAxios } from '@/api/api'
import { mkBrowserSentry } from '@/sentry'

configureBrowserAxios()

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

const router = mkGuestRouter()
pinia.use(RouterPlugin(router))
setActivePinia(pinia)

const app = createApp(App)
app.use(pinia)
app.use(router)
const { init: initPostHog } = usePostHog()
initPostHog()

if (import.meta.env.VUE_APP_VERSION) {
  mkBrowserSentry(app, router)
}
useSettingStore().fetch()
router.isReady().then(() => app.mount('#app'))
