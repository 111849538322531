import { createGlobalState } from '@vueuse/core'
import posthog, { PostHog } from 'posthog-js'
import { RouteLocationNormalized } from 'vue-router'

interface PostHogState {
  isInitialized: boolean
  instance: PostHog | null
  isIdentified: boolean
}
interface Venue {
  slug: string
  name: string
  id: number
}
interface IdentifyOptions {
  email: string
  name: string
  venue: Venue
}

export const usePostHog = createGlobalState(() => {
  const state: PostHogState = {
    isInitialized: false,
    instance: null,
    isIdentified: false,
  }

  function init() {
    if (state.isInitialized) return state.instance
    if (typeof window !== 'object' || !window.posthog?.apiKey) {
      console.warn('No posthog api key found')
      state.isInitialized = true
      return null
    }

    const instance = posthog.init(window.posthog.apiKey, {
      api_host: window.posthog.host,
      capture_pageview: false, // We'll handle this manually
      capture_pageleave: false, // We'll handle this manually
      autocapture: true, // For session recordings
    })

    if (instance) {
      state.instance = instance
      state.isInitialized = true
    }

    return state.instance
  }

  function group(venue: Venue) {
    if (init()) {
      state.instance?.group('venue', venue.slug, {
        id: venue.id,
        name: venue.name,
        slug: venue.slug,
      })
    }
  }

  function identify(userId: string, properties: IdentifyOptions) {
    if (init()) {
      if (!state.isIdentified) {
        state.instance?.identify(userId, properties)
        state.instance?.capture('$identify', { distinctId: userId })
        state.instance?.alias(userId, properties?.email)
        group(properties.venue)
        state.isIdentified = true
      }
    }
  }

  function capture(eventName: string, properties?: Record<string, any>) {
    if (init()) {
      state.instance?.capture(eventName, properties)
    }
  }

  function capturePageEvent(eventName: string, route: RouteLocationNormalized | null) {
    if (!route) return
    capture(eventName, {
      query: route.query,
      pageTitle: route.meta.pageTitle,
    })
  }

  function capturePageview(route: RouteLocationNormalized | null) {
    capturePageEvent('$pageview', route)
  }

  function capturePageleave(route: RouteLocationNormalized | null) {
    capturePageEvent('$pageleave', route)
  }

  function reset() {
    if (state.instance) {
      state.instance.reset(true)
      state.instance = null
    }
    state.isInitialized = false
    state.isIdentified = false
  }

  function isFeatureEnabled(flagKey: string): boolean {
    if (init()) {
      return state.instance?.isFeatureEnabled(flagKey) ?? false
    }
    return false
  }

  return {
    init,
    identify,
    group,
    capture,
    capturePageview,
    capturePageleave,
    reset,
    isFeatureEnabled,
    getInstance: () => state.instance,
  }
})
