import { AxiosRequestConfig, AxiosResponse } from 'axios'
import moment from 'moment'
import { RouteLocationNormalized } from 'vue-router'

import { HasId } from '@/models'

import { TypedId } from '@generated/types'

export async function gtmEvt(name: string) {
  if (!window.dataLayer) {
    return
  }
  window.dataLayer.push({ event: name })
  if (window.hj) {
    window.hj('event', name)
  }
}

export async function gtmPageView(to: RouteLocationNormalized) {
  if (!window.dataLayer) {
    return
  }
  let pageTitle = to.meta?.pageTitle || to.name
  if (to.fullPath.includes('venue')) {
    pageTitle = 'Venue - ' + pageTitle
  }
  window.dataLayer.push({
    event: 'page_view',
    page_path: to.fullPath,
    page_name: to.name,
    page_title: pageTitle,
  })
}

export function todayIsoStr() {
  return moment().format('YYYY-MM-DD')
}

export function currentTime() {
  return moment().format('HH:mm')
}

export function sum(input: number[]) {
  return input.reduce((i, j) => i + j, 0)
}

export function round2(input: number) {
  return Math.round(input * 100) / 100
}

export function fakeResponse<T>(data: T): AxiosResponse<T> {
  return {
    data: data,
    status: 200,
    statusText: 'ok',
    headers: {},
    config: null as unknown as AxiosRequestConfig,
  }
}

export function nextName(name: string) {
  const parsed = name.match(/(.*[^0-9])?([0-9]+)$/) // separate ending number part from anything before
  if (parsed) {
    return `${parsed[1] || ''}${Number(parsed[2]) + 1}`
  }
  return name
}

export function stringToOption(str: string) {
  return { label: str, value: str }
}

type SortParam<T> = string & keyof T
type ReversibleSortParam<T> = SortParam<T> | `-${SortParam<T>}`

function compareNumericProperty(property: string, reversed: boolean) {
  if (reversed) {
    return (a, b) => {
      return b[property] - a[property]
    }
  } else {
    return (a, b) => {
      return a[property] - b[property]
    }
  }
}

function compareStringProperty(property: string, reversed: boolean) {
  if (reversed) {
    return (a, b) =>
      (b[property] || '')
        .trim()
        .toLowerCase()
        .localeCompare((a[property] || '').trim().toLowerCase())
  } else {
    return (a, b) =>
      (a[property] || '')
        .trim()
        .toLowerCase()
        .localeCompare((b[property] || '').trim().toLowerCase())
  }
}

function isReversed<T>(property: ReversibleSortParam<T>): property is `-${SortParam<T>}` {
  return property[0] === '-'
}

function cleanParam<T>(property: ReversibleSortParam<T>): SortParam<T> {
  if (isReversed(property)) {
    // @ts-ignore
    return property.slice(1)
  } else {
    return property
  }
}

function comparatorForProperty<T>(sample: T, property: ReversibleSortParam<T>) {
  const propName = cleanParam(property)
  const numVal = Number(sample[propName])
  if (Number.isFinite(numVal)) {
    return compareNumericProperty(propName, isReversed(property))
  } else {
    return compareStringProperty(propName, isReversed(property))
  }
}

export function sortBy<T>(arr: T[], property: ReversibleSortParam<T>): T[] {
  if (!arr || !arr.length) {
    return []
  }
  return arr.sort(comparatorForProperty(arr[0], property))
}

function compareMulti<T>(comparators) {
  return (a: T, b: T) => {
    for (const comparator of comparators) {
      const result = comparator(a, b)
      if (result !== 0) {
        return result
      }
    }
    return 0
  }
}

export function sortByMultiple<T>(arr: T[], properties: ReversibleSortParam<T>[]): T[] {
  if (!arr || !arr.length) {
    return []
  }
  const comparators = properties.map(p => comparatorForProperty(arr[0], p))
  return arr.sort(compareMulti(comparators))
}

export function sortByName(arr: { name: string }[]) {
  return sortBy(arr, 'name')
}

export const sortReservations = (a, b) => {
  const aname = a?.user?.last_name.toLowerCase() || ''
  const bname = b?.user?.last_name.toLowerCase() || ''
  return aname.localeCompare(bname)
}

export function byId<F>(id: TypedId<F>) {
  return (obj: HasId<F>) => obj.id == id
}

export function upsertById<F, T extends HasId<F>>(obj: T, target: T[]): T {
  const idx = target.findIndex(byId(obj.id))
  if (idx < 0) {
    target.push(obj)
  } else {
    target.splice(idx, 1, obj) // preserves reactivity
  }
  return obj
}

export function deleteById<F, T extends HasId<F>>(id: TypedId<F>, target: T[]): void {
  const index = target.findIndex(byId(id))
  if (index >= 0) {
    target.splice(index, 1)
  }
}

export function defaultNumberOfGuests(
  tables: { maximum_guests: number; standing_room: boolean }[],
): number {
  if (tables.every(t => !t.standing_room)) {
    return sum(tables.map(t => t.maximum_guests))
  } else {
    return 2
  }
}

export const activeClass = (flag: boolean) => (flag ? 'font-bold text-black' : '')

export function cleanString(str: string) {
  return str
    .toLowerCase()
    .trim()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
}

export function inlineText(text: string): string {
  return text.replace(/\s+/gm, ' ')
}

export function snakeToCapitalWords(text: string): string {
  return text
    .split('_')
    .filter(x => x.length > 0)
    .map(x => x.charAt(0).toUpperCase() + x.slice(1))
    .join(' ')
}

export function readCookie(name: string): string | undefined {
  if (typeof document === 'undefined') {
    return undefined
  }
  return document.cookie
    .split('; ')
    .find(row => row.startsWith(`${name}=`))
    ?.split('=')[1]
}

export const ROOM_TABLE_RELATIVE_WIDTH = 112
